import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import axios from 'axios'; // Import axios

class MainPage extends Component
{
  state = {
    windowOpened: false,
    email: '',           // Email address from sessionStorage
    tripsData: [],       // Data received from the GET API
    loading: true,       // Loading state for the API call
    error: null,         // Error state in case the API call fails
  };

  // Method to fetch data from the API using axios
  // Method to fetch data from the API using axios
  fetchTrips = async (email) =>
  {
    try
    {
      const token = 'Bearer ' + sessionStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}api/v3/trips/forms`, {
        params: { EmailAddress: email, Status: 'OnlineForm' }, // Pass email as a query parameter
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          "Upgrade-Insecure-Requests": 1,
        },
      });
      // Update state with the fetched data
      this.setState({ tripsData: response.data, loading: false });
    } catch (error)
    {
      this.setState({ error: error.message, loading: false });
    }
  };

  // Lifecycle method to get email from sessionStorage and call API
  componentDidMount()
  {
    const email = sessionStorage.getItem('mail'); // Retrieve email from sessionStorage
    if (email)
    {
      this.setState({ email }, () =>
      {
        // Call the API with the email address
        this.fetchTrips(email);
      });
    } else
    {
      this.setState({ loading: false, error: 'No email found in sessionStorage' });
    }
  }

  render()
  {
    const { loading, tripsData, error } = this.state;

    return (
      <BaseLayout>
        <div onMouseMove={this.HandleMove} style={{ padding: '40px' }}>
          <p className='landing-text'>Welcome to Vialto Partners downstream business travel compliance technology. <br></br>
            To complete travel questionnaire, use the full website link previously provided. <br></br>
            Please contact your Vialto Partners engagement team with any questions.<br></br>
            For more information on Vialto Partners travel compliance solutions click <a href="https://vialto.com/" target="_blank" style={{ display: 'contents', color: '#0A838A' }}>here</a></p>
          {/* Display a loading message while fetching data */}
          {/*loading && <p>Loading trips...</p>*/}

          {/* Display error if any */}
          {/*error && <p>Error: {error}</p>*/}

          {/* Display the table if data is available */}
          {!loading && !error && tripsData.length > 0 && (
            <div className='admin_table_wrapper'>
              <table border="1" cellPadding="10" className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Trip ID</th>
                    <th>Record Locator</th>
                    <th>Home Office</th>
                    <th>From Country</th>
                    <th>To Country</th>
                    <th>Departure Date</th>
                    <th>Obligation</th>
                    <th>Link to Form</th>
                  </tr>
                </thead>
                <tbody>
                  {tripsData.map((trip, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{trip.tripId}</td>
                      <td>{trip.recordLocator}</td>
                      <td>{trip.homeCountry}</td>
                      <td>{trip.from}</td>
                      <td>{trip.to}</td>
                      <td>{new Date(trip.departureDate).toLocaleDateString()}</td>
                      <td>{trip.service}</td>
                      <td><a href={trip.link} target="_self" rel="noopener noreferrer">Open Link</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Show a message if no trips were found */}
          {!loading && !error && tripsData.length === 0 && (
            <p>No trips found for the given email address.</p>
          )}
        </div>
      </BaseLayout>
    );
  }
}

export default MainPage;
