import React, { Component } from 'react';
import { makeRequest } from '../../features/communication/request';
import { Container, Row, Col } from 'reactstrap';
import jsonLogic from 'json-logic-js/logic.js';
import { Form, Button, message, Progress, Tooltip, Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';

import Loader from 'react-loader-spinner';

import moment from 'moment';
import FormBuilder from 'antd-form-builder';
import './defineAntdWidgets.js';
import 'antd/dist/antd.css';
import './MyTripsForm.css';
import ChatBotComponent from '../chatbot/chatbotWindow/chatbotWindow';

import TooltipSVG from './tooltip.svg';
import ManageDelegationModal from './ManageDelegationModal';
import { BaseLayoutContext } from 'layouts/baseLayout/BaseLayoutProvider';
import Title from './Title';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const tooltip = () => (
  <img src={TooltipSVG} alt="" style={{ width: '18px', verticalAlign: 'bottom', marginLeft: '8px' }} />
);

const SAVE_ACTION = 'Save';
const SUBMIT_ACTION = 'Submit';
const SAVE_REGION_ACTION = 'SaveRegions';
const MAPPING_TO_MESSAGE = {
  [SAVE_ACTION]: 'Form has been saved',
  [SUBMIT_ACTION]: 'Form has been submitted',
  [SAVE_REGION_ACTION]: 'Region has been changed',
};

const isPlainObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';

const mapOptions = (options) =>
{
  if (!Array.isArray(options))
  {
    throw new Error('Options should be array in form builder meta.');
  }
  return options.map((opt) =>
  {
    if (Array.isArray(opt))
    {
      return { value: opt[0], label: opt[1] };
    } else if (isPlainObject(opt))
    {
      return opt;
    } else
    {
      return { value: opt, label: opt };
    }
  });
};

const arrayUniqueByKey = (array, key) => [
  ...new Map(array.map((item) => [item[key], item])).values(),
];

const justifySelect = (field) =>
{
  if (field.widget !== 'select')
  {
    return field;
  }
  return {
    ...field,
    widgetProps: {
      ...field.widgetProps,
      showSearch: true,
    },
    children: arrayUniqueByKey(mapOptions(field.options), 'value').map((opt, ind) => (
      <Select.Option value={opt.value} key={opt.value + ind}>
        {opt.label}
      </Select.Option>
    )),
  };
};

class MyForms extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      data: [],
      headformData: null,
      formData: null,
      formsMetaData: null,
      formsGSSMetaData: null,
      finish: false,
      formInformation: null,
      formInformationGss: null,
      submitted: false,
      loading: true,
      chatVisible: false,
      endpoint: process.env.REACT_APP_BACKEND_API_V3 + '/form3' + this.props.match.params.id,
      headform: process.env.REACT_APP_BACKEND_API_V3 + '/trip/questionnaire/details' + this.props.match.params.id,
      lastFocusedFieldKey: null,
      currentStep: 0,
      stepsLength: 0,
      wizardFields: {},
      onlyPWD: false,
      isChecked: false,
      service: null, // Add service to state
    };

    this.formRef = React.createRef();
    this.formGssRef = React.createRef();
  }
  static contextType = BaseLayoutContext;
  handleCheckboxChange = (event) =>
  {
    this.setState({ isChecked: event.target.checked });
  };
  componentDidMount()
  {
    makeRequest({
      method: 'get',
      url: 'api/v3/trip/questionnaire/details/' + this.props.match.params.id,
      hostName: process.env.REACT_APP_BACKEND_API_V3
    })
      .then((resp) =>
      {
        this.setState({ headformData: resp.data });

        // Extract the service parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const service = urlParams.get('service');
        this.setState({ service }, () =>
        {
          // Call the appropriate method based on the service parameter
          if (service === 'PostedWorkersDirective')
          {
            this.getFormPWDContent();
          } else if (service === 'SocialSecurity')
          {
            this.getFormGSSContent();
          }
        });

        this.context.setShowSettingsButton(typeof resp.data.personal_assistant === 'string');
      })
      .catch((err) =>
      {
        if (err.response && err.response.data)
        {
          this.setState({ errorMSG: err.response.data.MSG, loading: false });
          message.error(err.response.data.MSG);
        } else
        {
          err.response ? message.error(err.response.data) : message.error(err.message);
        }
      });

    if (!this.state.submitted && !this.state.formInformation && !this.state.formInformationGss)
    {
      setTimeout(() =>
      {
        this.updateProgress();
      }, 2400);
    }

    setTimeout(() =>
    {
      if (this.state.headformData && this.state.headformData.chatbotVisible === 'True')
      {
        this.setState({ chatVisible: true });
      }
    }, 4000);

    // Extract the service parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const service = urlParams.get('service');
    this.setState({ service });
  }

  getFormPWDContent = () =>
  {
    makeRequest({
      method: 'get',
      url: 'api/v3/trip/questionnaire/PostedWorkersDirective/' + this.props.match.params.id,
      hostName: process.env.REACT_APP_BACKEND_API_V3
    })
      .then((resp) =>
      {
        let forms_ = [];
        if (typeof resp.data.form === 'string')
        {
          this.setState({
            formInformation: resp.data.form,
            loading: false,
          });
        } else
        {
          resp.data.form.forEach((category) =>
          {
            Object.entries(category).forEach(([key, value]) =>
            {
              if (key.includes('wizard-'))
              {
                forms_.push({ category: key, type: 'wizard', value: this.getWizard(value, 'PWD') });
              } else
              {
                forms_.push({
                  category: key,
                  type: 'questions',
                  value: this.getMeta(value, 'PWD'),
                });
              }
            });
          });
          this.setState({
            formsMetaData: forms_,
            onlyPWD: resp.data.block,
            loading: false,
          });
          this.updateProgress();
        }
      })
      .catch(() =>
      {
        this.setState({ loading: false });
      });
  };

  getFormGSSContent = () =>
  {
    makeRequest({
      method: 'get',
      url: 'api/v3/trip/questionnaire/SocialSecurity/' + this.props.match.params.id,
      hostName: process.env.REACT_APP_BACKEND_API_V3
    })
      .then((resp) =>
      {
        let forms_ = [];
        if (typeof resp.data.form === 'string')
        {
          this.setState({
            formInformationGss: resp.data.form,
            loading: false,
          });
        } else
        {
          resp.data.form.forEach((category) =>
          {
            Object.entries(category).forEach(([key, value]) =>
            {
              if (key.includes('wizard-'))
              {
                forms_.push({ category: key, type: 'wizard', value: this.getWizard(value, 'GSS') });
              } else
              {
                forms_.push({
                  category: key,
                  type: 'questions',
                  value: this.getMeta(value, 'GSS'),
                });
              }
            });
          });
          this.setState({
            formsGSSMetaData: forms_,
            loading: false,
          });
          this.updateProgress();
        }
      })
      .catch(() =>
      {
        this.setState({ loading: false });
      });
  };

  updateWizard = (key, logical, type) =>
  {
    let data;
    if (type === 'PWD')
    {
      data = this.formRef.current.getFieldsValue();
    } else if (type === 'GSS')
    {
      data = this.formGssRef.current.getFieldsValue();
    }
    return jsonLogic.apply(logical.rule, data);
  };

  updatePWD = (evt) =>
  {
    this.setState({ formsMetaData: null, loading: true });
    if (this.formRef.current && Object.keys(this.formRef.current.getFieldsValue()).length > 0)
    {
      this.sendPWDForm(evt, SAVE_REGION_ACTION, null);
    }
    if (this.formGssRef.current && Object.keys(this.formGssRef.current.getFieldsValue()).length > 0)
    {
      this.sendGSSForm(evt, SAVE_REGION_ACTION, null);
    }

  };

  updateGSS = (evt) =>
  {
    this.setState({ formsGSSMetaData: null, loading: true });
    if (this.formRef.current && Object.keys(this.formRef.current.getFieldsValue()).length > 0)
    {
      this.sendPWDForm(evt, SAVE_REGION_ACTION, null);
    }
    if (this.formGssRef.current && Object.keys(this.formGssRef.current.getFieldsValue()).length > 0)
    {
      this.sendGSSForm(evt, SAVE_REGION_ACTION, null);
    }

  };

  handleFinish = (evt) =>
  {
    if (this.formRef.current && Object.keys(this.formRef.current.getFieldsValue()).length > 0)
    {
      this.sendPWDForm(evt, SUBMIT_ACTION, null);
    }
    if (this.formGssRef.current && Object.keys(this.formGssRef.current.getFieldsValue()).length > 0)
    {
      this.sendGSSForm(evt, SUBMIT_ACTION, null);
    }
  };

  handleGSSFinish = (evt) =>
  {
    if (this.formGssRef.current && Object.keys(this.formGssRef.current.getFieldsValue()).length > 0)
    {
      this.sendGSSForm(evt, SUBMIT_ACTION);
    }
  };

  saveForm = (evt) =>
  {
    if (this.formRef.current && Object.keys(this.formRef.current.getFieldsValue()).length > 0)
    {
      this.sendPWDForm(evt, SAVE_REGION_ACTION, null);
      this.setState({
        formInformationGss: "",
      });

    }
    if (this.formGssRef.current && Object.keys(this.formGssRef.current.getFieldsValue()).length > 0)
    {
      this.sendGSSForm(evt, SAVE_REGION_ACTION, null);
      this.setState({
        formInformation: "",

      });
    }
  };

  submitForm = (evt) =>
  {
    Promise.all([this.formGssRef.current.validateFields(), this.formRef.current.validateFields()])
      .then(([resGSS, res]) =>
      {
        this.formRef.current.submit();

      })
      .catch((err) => err);
    //this.formRef.current.submit() && this.formGssRef.current.submit()
  };

  sendPWDForm = (evt, action, fields) =>
  {
    makeRequest({
      method: 'put',
      url: 'api/v3/trip/questionnaire/PostedWorkersDirective/' + this.props.match.params.id + '?action=' + action,
      hostName: process.env.REACT_APP_BACKEND_API_V3,
      body: fields ? fields : this.formRef.current.getFieldsValue(),
    })
      .then((resp) =>
      {
        if (action === 'SaveRegions')
        {
          if (this.formRef.current)
          {
            this.formRef.current.resetFields();
          }
          this.setState({ formsMetaData: null });
          this.getFormPWDContent();
        } else
        {
          message.info(MAPPING_TO_MESSAGE[action]);
        }
        if (action === SUBMIT_ACTION)
        {
          this.setState(
            {
              submitted: true,
              responseMSG: 'Thank you for responding to the myTrips+ Questionnaire'
            });
        }
      })
      .catch((error) =>
      {
        error.response ? message.error(error.response.data) : message.error(action + ' error');
      });
  };

  sendGSSForm = (evt, action, fields) =>
  {
    makeRequest({
      method: 'put',
      url: 'api/v3/trip/questionnaire/SocialSecurity/' + this.props.match.params.id + '?action=' + action,
      hostName: process.env.REACT_APP_BACKEND_API_V3,
      body: fields ? fields : this.formGssRef.current.getFieldsValue(),
    })
      .then((resp) =>
      {
        if (action === 'SaveRegions')
        {
          if (this.formGssRef.current)
          {
            this.formGssRef.current.resetFields();
          }
          this.setState({ formsGSSMetaData: null });
          this.getFormGSSContent();
        } else
        {
          message.info(MAPPING_TO_MESSAGE[action]);
        }
        if (action === SUBMIT_ACTION)
        {
          this.setState(
            {
              submitted: true,
              responseMSG: 'Thank you for responding to the myTrips+ Questionnaire'
            });
        }
      })
      .catch((error) =>
      {
        error.response ? message.error(error.response.data) : message.error(action + ' error');
      });
  };

  updateProgress = () =>
  {
    if (!this.formRef.current || !this.formGssRef.current)
    {
      return;
    }
    let allfields = Object.values(this.formRef.current.getFieldsValue());
    let allfieldsGss = Object.values(this.formGssRef.current.getFieldsValue());

    Array.prototype.push.apply(allfields, allfieldsGss);

    let filledfields = allfields.filter((el) => el);

    this.setState({ formProgress: Math.round((filledfields.length / allfields.length) * 100) });
  };

  checkFormAvailable = () =>
  {
    if (
      (this.state.formsMetaData && this.state.formsMetaData.length > 0) ||
      (this.state.formsGSSMetaData && this.state.formsGSSMetaData.length > 0)
    )
    {
      return true;
    }

    return false;
  };

  checkRegionsInForm = (formData) =>
  {
    if (formData)
    {
      if (
        formData.length === 1 &&
        formData[0].value &&
        formData[0].value.fields &&
        formData[0].value.fields.length === 1
      )
      {
        return true;
      }
    }
    return false;
  };

  getWizard = (data, advice) =>
  {
    let enrichedData = data.map((element) =>
    {
      let logical = element.logical;
      let key = element.key;
      let label = element.label;
      let elements = element.steps.map((step) =>
      {
        let fields_ = [];
        step.formMeta.fields.map((field) =>
        {
          let rules = [{ required: true, message: `${field.label} is required` }]
          let newfield = {
            ...field,
            rules: rules
          };
          fields_.push(newfield);
        });
        return {
          ...step,
          formMeta: { ...step.formMeta, fields: fields_ },
        };
      });

      return {
        steps: elements,
        key: key,
        logical: logical,
        advice: advice,
        label: label
      };
    });
    return enrichedData[0];
  };

  getMeta = (data, advice) =>
  {
    const enrichedData = data.map((field) =>
    {
      let rules = [{ required: true, message: `${field.label} is required` }]

      if (field.validation)
      {
        let rule = {
          pattern: new RegExp(field.validation),
          message: field.validationMessage ? field.validationMessage : "Format is wrong"
        }
        rules.push(rule)
      }

      if (field.children)
      {
        delete field.children;
        if (advice === 'GSS')
        {
          return {
            ...field,
            rules: rules,
            widgetProps: { onChange: this.updateGSS },
          };
        } else
        {
          return {
            ...field,
            rules: rules,
            widgetProps: { onChange: this.updatePWD },
          };
        }
      } else
      {
        return {
          ...field,
          rules: rules,
        };
      }
    });

    let meta = {
      columns: 1,
      formItemLayout: null,
      colon: true,
      fields: enrichedData,
    };

    return meta;
  };

  handleNext = (evt, value) =>
  {

    Promise.all([this.formRef.current.validateFields(), this.formGssRef.current.validateFields()])
      .then(([pwd, gss]) =>
      {

        let logicvalue = this.updateWizard(value.key, value.logical, value.advice);
        if (logicvalue !== 'None')
        {
          let fields;
          if (value.advice === "PWD")
          {

            fields = this.formRef.current.getFieldsValue();
            fields[value.key] = logicvalue;
            this.formRef.current.setFieldsValue(fields);

            this.setState({ formsMetaData: null, loading: true });
            this.sendPWDForm(evt, SAVE_REGION_ACTION, fields);

          } else if (value.advice === "GSS")
          {

            fields = this.formGssRef.current.getFieldsValue();
            fields[value.key] = logicvalue;
            this.formGssRef.current.setFieldsValue(fields);

            this.setState({ formsGSSMetaData: null, loading: true });
            this.sendGSSForm(evt, SAVE_REGION_ACTION, fields);

          } else if (value.advice === "COMMON")
          {

            fields = this.formRef.current.getFieldsValue();
            fields[value.key] = logicvalue;
            this.formRef.current.setFieldsValue(fields);

            this.setState({
              formsMetaData: null,
              formsGSSMetaData: null,
              loading: true
            });
            this.sendPWDForm(evt, SAVE_REGION_ACTION, fields);
            this.sendGSSForm(evt, SAVE_REGION_ACTION, fields);
          }
        } else
        {
          let step = this.state.currentStep;
          this.setState({ currentStep: step + 1 });
        }
      }
      ).catch((err) => err);


  };

  handleBack = () =>
  {
    let step = this.state.currentStep;
    this.setState({ currentStep: step - 1 });
  };

  compareForms = (first, second) =>
  {
    let updateForm = false;
    let first_ = []
    let second_ = []


    if (first && second && first.length === second.length)
    {
      first.map((element, index) =>
      {
        let test = second[index]
        if (element.type === "wizard" && element.value.key === test.value.key)
        {
          updateForm = true;
          element.value.advice = "COMMON"
          //second.splice(index, 1)
        } else
        {
          second_.push(test)
        }
        first_.push(element)
      })
    }

    if (updateForm)
    {
      this.setState({ formsMetaData: first_, formsGSSMetaData: second_ })
    }

  }

  componentDidUpdate(prevProps, prevState)
  {
    const { formsMetaData, formsGSSMetaData, lastFocusedFieldKey } = this.state;


    this.compareForms(formsMetaData, formsGSSMetaData)

    if (
      lastFocusedFieldKey &&
      (prevState.formsMetaData !== formsMetaData || prevState.formsGSSMetaData !== formsGSSMetaData)
    )
    {
      const fieldsKeys = [
        ...(formsMetaData
          ? formsMetaData.flatMap(({ value: { fields } }) => fields.map((field) => field.key))
          : []),
        ...(formsGSSMetaData
          ? formsGSSMetaData.flatMap(({ value: { fields } }) => fields.map((field) => field.key))
          : []),
      ];

      if (fieldsKeys.length > 0)
      {
        const lastFocusedFieldKeyIndex = fieldsKeys.indexOf(lastFocusedFieldKey);
        const nextFieldIndex =
          lastFocusedFieldKeyIndex < fieldsKeys.length - 1
            ? lastFocusedFieldKeyIndex + 1
            : lastFocusedFieldKeyIndex < fieldsKeys.length + 1
              ? lastFocusedFieldKeyIndex
              : 0;
        let nextFieldInstance =
          this.formRef.current?.getFieldInstance(fieldsKeys[nextFieldIndex]) ||
          this.formGssRef.current?.getFieldInstance(fieldsKeys[nextFieldIndex]);
        if (nextFieldInstance)
        {
          nextFieldInstance.focus();
        }
      }
    }
  }

  prettyPrint = (text) =>
  {
    try
    {
      if (text)
      {
        return text.replaceAll('\\n', '\n');
      }
      return '';
    } catch (error)
    {
      console.error('Error occurred:', error);
      return ''; // Handle the error gracefully
    }
  };


  split = (string) =>
  {
    return string.split('|');
  };

  render()
  {
    const { isChecked } = this.state;
    return (
      <Container fluid="true" className="screen-container">
        <ManageDelegationModal
          personalAssistant={this.state.headformData?.personal_assistant}
          segmentId={this.props.match.params.id}
        />
        <div>
          <Title title="Business Travel Compliance" subTitle="Additional Information Questionnaire" />
          {this.state.loading ? (
            <div
              style={{
                width: '100%',
                height: '100',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
              }}
            >
              <Loader type="Circles" color="#1CDBBC" height={80} width={80} />
            </div>
          ) : (
            <>
              <Row className="mw plr-20">
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  {this.state.headformData && !this.state.submitted && this.checkFormAvailable() ? (
                    <div className="header-info">
                      {`Additional information is needed to file notifications with authorities\
                    for your upcoming trip from ${this.state.headformData.travel_from} to ${this.state.headformData.travel_to
                        }\
                    on ${moment(new Date(Date.parse(this.state.headformData.travel_date))).format(
                          'MMMM Do YYYY'
                        )}.`}
                    </div>
                  ) : null}
                </Col>
                {this.state.headformData?.personal_assistant?.length > 0 && (
                  <Col sm="12" md={{ size: 6, offset: 3 }} style={{ fontSize: 22, color: 'black' }}>
                    <div className="header-info-form">
                      This myTrips+ questionnaire has been delegated to{' '}
                      <div
                        style={{
                          display: 'inline-block',
                          color: '#0b97a6',
                          textDecoration: 'underline',
                        }}
                      >
                        {this.state.headformData?.personal_assistant}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
              <>
                {!this.state.submitted && this.checkFormAvailable() ? (
                  <div className="form-container">
                    <Row className="plr-20" >
                      <Col style={{ marginTop: '30px' }} sm="12" md={{ size: 12 }}>
                        <Progress strokeColor="#1CDBBC" percent={this.state.formProgress} />
                      </Col>
                    </Row>

                    <Row className="plr-20">
                      <Col sm="12" md={{ size: 12 }}>
                        <div>
                          <Form
                            ref={this.formRef}
                            layout="vertical"
                            onFinish={this.handleFinish}
                            onValuesChange={this.updateProgress}
                            validateMessages={{ required: '' }}
                          >
                            {this.state.formsMetaData
                              ? this.state.formsMetaData.map((element, ind) =>
                              {
                                if (element.type === 'questions')
                                {
                                  return (
                                    <fieldset key={`pwd-fieldset-${ind}`}>
                                      <div className="category">
                                        {this.prettyPrint(element.category)}
                                      </div>
                                      <FormBuilder
                                        meta={{
                                          ...element.value,
                                          fields: element.value.fields.map((field, ind) =>
                                            justifySelect({
                                              ...field,
                                              key: field.key + '#' + ind,
                                              name: field.key,
                                              help: null,
                                              label: (
                                                <div style={{ 'white-space': 'pre-wrap' }}>
                                                  <div style={{ color: '#707070' }}>
                                                    {ReactHtmlParser(this.split(field.label)[0])}
                                                    {field.help && (
                                                      <Tooltip
                                                        title={this.prettyPrint(field.help)}
                                                        trigger={['click']}
                                                        placement="topLeft"
                                                      >
                                                        <Icon component={tooltip} />
                                                      </Tooltip>
                                                    )}
                                                  </div>
                                                  {this.split(field.label).length > 1 ? (
                                                    <div>{ReactHtmlParser(this.split(field.label)[1])}</div>
                                                  ) : null}
                                                </div>
                                              ),
                                              formItemProps: {
                                                onClick: () =>
                                                  this.setState({
                                                    lastFocusedFieldKey: field.key,
                                                  }),
                                              },
                                            })
                                          ),
                                        }}
                                        form={this.formRef}
                                      />
                                    </fieldset>
                                  );
                                }
                                else if (element.type === 'wizard')
                                {
                                  let currentStep_ = this.state.currentStep;
                                  return (
                                    <fieldset key={`pwd-fieldset-${ind}`}>
                                      <div
                                        style={{
                                          background: '#D5D5D5',
                                          padding: '20px',
                                          margin: '30px 0',
                                        }}
                                      >
                                        <div>
                                          {
                                            this.prettyPrint(element.value.label)
                                          }
                                        </div>
                                        <FormBuilder
                                          form={this.formRef}
                                          meta={{
                                            ...element.value.steps[currentStep_].formMeta,
                                            fields: element.value.steps[currentStep_].formMeta.fields.map((field, ind) =>
                                              justifySelect({
                                                ...field,
                                                key: field.key + '#' + ind,
                                                name: field.key,
                                                help: null,
                                                label: (
                                                  <div style={{ 'white-space': 'pre-wrap' }}>
                                                    <div style={{ color: '#707070' }}>
                                                      {ReactHtmlParser(this.split(field.label)[0])}
                                                      {field.help && (
                                                        <Tooltip
                                                          title={this.prettyPrint(field.help)}
                                                          trigger={['click']}
                                                          placement="topLeft"
                                                        >
                                                          <Icon component={tooltip} />
                                                        </Tooltip>
                                                      )}
                                                    </div>
                                                    {this.split(field.label).length > 1 ? (
                                                      <div>{ReactHtmlParser(this.split(field.label)[1])}</div>
                                                    ) : null}
                                                  </div>
                                                ),
                                                formItemProps: {
                                                  onClick: () =>
                                                    this.setState({
                                                      lastFocusedFieldKey: field.key,
                                                    }),
                                                },
                                              })
                                            )
                                          }}
                                        />
                                        <Form.Item
                                          className="form-footer"
                                          style={{ textAlign: 'right' }}
                                        >
                                          {currentStep_ > 0 && (
                                            <Button
                                              onClick={this.handleBack}
                                              style={{ float: 'left', marginTop: '5px' }}
                                            >
                                              Back
                                            </Button>
                                          )}
                                          <label style={{ float: 'left', display: 'flex', alignItems: 'center' }}>
                                            <input style={{ marginRight: '10px' }}
                                              type="checkbox"
                                              checked={isChecked}
                                              onChange={this.handleCheckboxChange}
                                            />
                                            I confirm that I have reviewed the entity selected above
                                          </label>
                                          <Button
                                            type="primary"
                                            onClick={(evt) =>
                                            {
                                              this.handleNext(evt, element.value);
                                            }}
                                            disabled={!isChecked}
                                          >
                                            Next
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    </fieldset>
                                  );
                                }
                              })
                              : null}
                          </Form>
                          <div>---</div>
                          <Form
                            ref={this.formGssRef}
                            layout="vertical"
                            onFinish={this.handleGSSFinish}
                            onValuesChange={this.updateProgress}
                            validateMessages={{ required: '' }}
                          >
                            {this.state.formsGSSMetaData && !this.state.onlyPWD
                              ? this.state.formsGSSMetaData.map((element, ind) =>
                              {
                                if (element.type === 'questions')
                                {
                                  return (
                                    <fieldset key={`ss-fieldset-${ind}`}>
                                      <div className="category">
                                        {this.prettyPrint(element.category)}
                                      </div>
                                      <FormBuilder
                                        meta={{
                                          ...element.value,
                                          fields: element.value.fields.map((field, ind) =>
                                            justifySelect({
                                              ...field,
                                              key: field.key + '#' + ind,
                                              name: field.key,
                                              help: null,
                                              label: (
                                                <div style={{ 'white-space': 'pre-wrap' }}>
                                                  <div style={{ color: 'rgb(112, 112, 112)' }}>
                                                    {ReactHtmlParser(this.split(field.label)[0])}
                                                    {field.help && (
                                                      <Tooltip
                                                        title={this.prettyPrint(field.help)}
                                                        trigger={['click']}
                                                        placement="topLeft"
                                                      >
                                                        <Icon component={tooltip} />
                                                      </Tooltip>
                                                    )}
                                                  </div>
                                                  {this.split(field.label).length > 1 ? (
                                                    <div>{ReactHtmlParser(this.split(field.label)[1])}</div>
                                                  ) : null}
                                                </div>
                                              ),
                                              formItemProps: {
                                                onClick: () =>
                                                  this.setState({ lastFocusedFieldKey: field.key }),
                                              },
                                            })
                                          ),
                                        }}
                                        form={this.formGssRef}
                                      />
                                    </fieldset>
                                  );
                                } else if (element.type === 'wizard')
                                {
                                  let currentStep_ = this.state.currentStep;
                                  return (
                                    <fieldset key={`pwd-fieldset-${ind}`}>
                                      <div
                                        style={{
                                          background: '#D5D5D5',
                                          padding: '20px',
                                          margin: '30px 0',
                                        }}
                                      >
                                        <div>
                                          {
                                            this.prettyPrint(element.value.label)
                                          }
                                        </div>
                                        <FormBuilder
                                          form={this.formRef}
                                          meta={{
                                            ...element.value.steps[currentStep_].formMeta,
                                            fields: element.value.steps[currentStep_].formMeta.fields.map((field, ind) =>
                                              justifySelect({
                                                ...field,
                                                key: field.key + '#' + ind,
                                                name: field.key,
                                                help: null,
                                                label: (
                                                  <div style={{ 'white-space': 'pre-wrap' }}>
                                                    <div style={{ color: '#707070' }}>
                                                      {ReactHtmlParser(this.split(field.label)[0])}
                                                      {field.help && (
                                                        <Tooltip
                                                          title={this.prettyPrint(field.help)}
                                                          trigger={['click']}
                                                          placement="topLeft"
                                                        >
                                                          <Icon component={tooltip} />
                                                        </Tooltip>
                                                      )}
                                                    </div>
                                                    {this.split(field.label).length > 1 ? (
                                                      <div>{ReactHtmlParser(this.split(field.label)[1])}</div>
                                                    ) : null}
                                                  </div>
                                                ),
                                                formItemProps: {
                                                  onClick: () =>
                                                    this.setState({
                                                      lastFocusedFieldKey: field.key,
                                                    }),
                                                },
                                              })
                                            )
                                          }}
                                        />
                                        <Form.Item
                                          className="form-footer"
                                          style={{ textAlign: 'right' }}
                                        >
                                          {currentStep_ > 0 && (
                                            <Button
                                              onClick={this.handleBack}
                                              style={{ float: 'left', marginTop: '5px' }}
                                            >
                                              Back
                                            </Button>
                                          )}
                                          <label style={{ float: 'left', display: 'flex', alignItems: 'center' }}>
                                            <input style={{ marginRight: '10px' }}
                                              type="checkbox"
                                              checked={isChecked}
                                              onChange={this.handleCheckboxChange}
                                            />
                                            I confirm that I have reviewed the host entity selected above
                                          </label>
                                          <Button
                                            type="primary"
                                            onClick={(evt) =>
                                            {
                                              this.handleNext(evt, element.value);
                                            }}
                                            disabled={!isChecked}
                                          >
                                            Next
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    </fieldset>
                                  );
                                }
                              })
                              : null}
                          </Form>
                          <Form.Item className="form-footer">
                            <div className="buttons-container">
                              <Button
                                size="large"
                                type="default"
                                onClick={this.saveForm}
                                className="action-button save-button "
                              >
                                Save Draft
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                onClick={this.submitForm}
                                className="action-button"
                                htmlType="submit"
                              >
                                Submit
                              </Button>
                              &nbsp; &nbsp;
                            </div>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="submitted-form">
                    {this.state.submitted && (
                      <span>
                        <CheckOutlined />
                      </span>
                    )}
                    <span>
                      {this.state.loading ||
                        this.state.formInformation ||
                        this.state.formInformationGss ||
                        this.state.errorMSG ||
                        this.state.responseMSG || " "
                      }
                      .
                    </span>
                  </div>
                )}
              </>
            </>
          )}
          {this.state.chatVisible && this.state.headformData ? (
            <div className="form3-chat">
              <div className="form3-chatbot">
                <ChatBotComponent
                  headData={this.state.headformData}
                  tripId={this.props.match.params.id}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Container>
    );
  }
}

export default MyForms;